export const specials = [
  {
    title: '🆕 October Specials',
    byLine: 'ByLine 1',
    month: 9,
    body: 'Hallow is here',
    img_url: '/',
  },
  {
    title: '🆕 October Specials',
    byLine: 'ByLine 1',
    month: 9,
    body: 'Halloween is here Booooooooo',
    img_url: '/',
  },
  {
    title: '🆕 November Specials',
    byLine: 'ByLine 1',
    month: 10,
    body: 'Thanksgiving is here',
    img_url: '/',
  },
  {
    title: '🆕 February Specials',
    byLine: 'ByLine 1',
    month: 1,
    body: 'Valentine\'s Day is approaching and we have something special for you',
    img_url: '/',
  },
];
