export const images = [
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0076.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0076_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0074.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0074_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0069.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0069_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0067.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0067_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0066.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0066_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0062.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0062_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0060.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0060_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0059.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0059_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0049.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0049_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0045.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0045_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0042.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0042_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0035.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0035_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0034.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0034_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0032.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0032_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0029.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0029_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0024.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0024_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0016.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0016_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0011.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0011_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0010.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0010_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0008.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0008_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0006.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0006_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0005.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0005_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0004.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0004_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/40th.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/40th_thumb.png`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    // tags: [{ value: 'cupcakes' }, { value: 'banana pudding' }],
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/justice.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/justice_thumb.png`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    // tags: [{ value: 'cupcakes' }, { value: 'banana pudding' }],
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/kailyn11th.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/kailyn11th_thumb.png`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    // tags: [{ value: 'cupcakes' }, { value: 'banana pudding' }],
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/cupcakes.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/cupcakes_thumb.png`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    // tags: [{ value: 'cupcakes' }, { value: 'banana pudding' }],
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/drip.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/drip_thumb.png`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    // tags: [{ value: 'cupcakes' }, { value: 'banana pudding' }],
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0049-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0049-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0048-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0048-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0047-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0047-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0045-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0045-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0044-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0044-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0043-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0043-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0042-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0042-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0041-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0041-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0040-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0040-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0039-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0039-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0038-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0038-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0036-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0036-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0035-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0035-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0034-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0034-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0033-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0033-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0032-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0032-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0031-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0031-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0030-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0030-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0029-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0029-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0028-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0028-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0027-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0027-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0025-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0025-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0024-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0024-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0023-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0023-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0022-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0022-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0021-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0021-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0020-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0020-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0019-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0019-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0018-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0018-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0017-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0017-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0016-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0016-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0015-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0015-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0014-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0014-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0012-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0012-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0011-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0011-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0010-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0010-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0009-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0009-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0005-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0005-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0004-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0004-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0003-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0003-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0002-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0002-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/gallery/img0001-older.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/assets/gallery/img0001-older_thumb.jpg`,
    thumbnailWidth: 100,
    thumbnailHeight: 100,
    isSelected: false,
    caption: '',
    category: 'gallery',
  },
];
