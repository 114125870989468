export const products = [
  {
    title: 'Cupcakes',
    description: '',
    snippet: 'Our cupcakes are like a little taste of happiness!',
    prev_price: '$$$',
    current_price: '$$',
    classic_price: '$30/dozen',
    specialty_price: '$36/dozen',
    discount: 0.15,
    image: 'featured_cupcake.png',
    link: 'cupcakes',
    type: 'sweet',
    flavors: [
      ' Vanilla,',
      ' 🍓Strawberry,',
      ' 🍫Chocolate,',
      ' 🍋Lemon,',
      ' Red Velvet,',
      ' 👰🏾Wedding Cake,',
      ' 🥕Carrot',
    ],
    specialty_flavors: [
      ' Key Lime Surprise,',
      ' 🥜Peanut Butter Supreme,',
      ' 🍌Sam’s Banana Pudding,',
      ' Cookies & Cream',
    ],
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: true,
    isOnSale: false,
  },
  {
    title: 'Cakes',
    description: '',
    snippet: 'Our cakes are moist, tasty and made fresh every order.',
    prev_price: '',
    current_price: '',
    classic_price: 'Starting at $45-$125/each',
    specialty_price: 'Starting at $65-$145/each',
    sq6_title: '6" Square',
    sq6_price: 'Starting at $50 Classic / $70 Specialty',
    sq8_title: '8" Square',
    sq8_price: 'Starting at $75 Classic / $95 Specialty',
    sq10_title: '10" Square',
    sq10_price: 'Starting at $100 Classic / $120 Specialty',
    sq12_title: '12" Square',
    sq12_price: 'Starting at $125 Classic / $145 Specialty',
    rd6_title: '6" Round',
    rd6_price: 'Starting at $45 Classic / $65 Specialty',
    rd8_title: '8" Round',
    rd8_price: 'Starting at $65 Classic / $85 Specialty',
    rd10_title: '10" Round',
    rd10_price: 'Starting at $90 Classic / $110 Specialty',
    rd12_title: '12" Round',
    rd12_price: 'Starting at $115 Classic / $135 Specialty',
    discount: 0.15,
    image: 'featured_customcake.svg',
    link: 'cake',
    type: 'sweet',
    flavors: [
      ' Vanilla,',
      ' 🍓Strawberry,',
      ' 🍫Chocolate,',
      ' 🍋Lemon,',
      ' Red Velvet,',
      ' 👰🏾Wedding Cake,',
      ' 🥕Carrot',
    ],
    specialty_flavors: [
      ' Key Lime Surprise,',
      ' 🥜Peanut Butter Supreme,',
      ' 🍌Sam’s Banana Pudding,',
      ' Cookies & Cream',
    ],
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: true,
    isOnSale: false,
  },
  {
    title: 'Custom Cakes',
    description: '',
    snippet:
      'Custom cakes are designed and created to fit our clients wants and needs.',
    prev_price: '',
    current_price: 'Ask for a quote',
    discount: 0.15,
    image: 'featured_customcake.svg',
    link: 'cake',
    type: 'sweet',
    flavors: '',
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Drip Cakes',
    description: '',
    snippet:
      'Our drip cakes are dripped with melted chocolate or candy wafers and topped with candy, popcorn, and/or chocolate shards, etc.',
    prev_price: '',
    current_price: 'Starting at $85/each',
    discount: 0.15,
    image: 'dripcake.png',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Pound Cakes',
    description: '',
    snippet: '10" Pound cakes are perfect for holidays or any occasion.',
    prev_price: '',
    current_price: '$50/each',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: [' Classic,', ' 🍌Banana Pudding,', ' 🍓Strawberry,', ' 🍋Lemon'],
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Tier Cakes',
    description: '',
    snippet: 'Our tier cakes are perfect for weddings and parties.',
    prev_price: '',
    current_price: 'Ask for a quote',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Cake Tastings',
    description: '',
    snippet:
      'Clients can schedule a cake tasting for their upcoming event and sample our many desserts.',
    prev_price: '',
    current_price: 'Schedule an appointment',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Cookies',
    description: '',
    snippet: '',
    prev_price: '$$$',
    current_price: '$20/dozen',
    classic_price: '',
    specialty_price: '',
    discount: 0.15,
    image: 'featured_cookies.png',
    link: 'order',
    type: 'treat',
    flavors: [
      ' Sugar,',
      ' Ginger Molasses,',
      ' Oatmeal Raisin,',
      ' Cream Cheese Chocolate Chip',
    ],
    isSpecialty: false,
    isBestSeller: true,
    isFeatured: true,
    isOnSale: false,
  },
  {
    title: 'Cookies w/Candy',
    description: '',
    snippet:
      'Cookies with candy contain chocolate chip, peanut butter, or white chocolate chips along with the candy.',
    prev_price: '$$$',
    current_price: '$24/dozen',
    classic_price: '',
    specialty_price: '',
    discount: 0.15,
    image: 'featured_cookies.svg',
    link: 'order',
    type: 'treat',
    flavors: [
      ' M&M + Milk chocolate chip,',
      " Reese's Pieces + Peanut butter chips,",
      " Reese's Cup + Peanut butter chips,",
      ' Peppermint + White chocolate chips,',
      ' Cookies & Cream + White chocolate,',
      ' Trail Mix + Milk chocolate chip',
    ],
    isSpecialty: false,
    isBestSeller: true,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Cookies w/Edible Image',
    description: '',
    snippet:
      'Sugar cookies covered with royal icing (icing dries hard), topped with an edible image.',
    prev_price: '$$$',
    current_price: '$40/dozen',
    classic_price: '',
    specialty_price: '',
    discount: 0.15,
    image: 'featured_cookies.svg',
    link: 'order',
    type: 'treat',
    flavors: 'Sugar',
    isSpecialty: false,
    isBestSeller: true,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Pretzel Rods',
    description: '',
    snippet: '',
    prev_price: '',
    current_price: '$18/dozen',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'treat',
    flavors: [' 🍫Chocolate-covered'],
    isSpecialty: false,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Lemon Squares',
    description: '',
    snippet: 'Our 9" X 13" trays are delicious and a fan favorite.',
    prev_price: '$$$',
    current_price: '$30/tray',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'treat',
    isSpecialty: false,
    isBestSeller: true,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: 'Key Lime Supreme',
    description:
      'Key lime cake with a graham cracker crust, filled with a key lime curd, and topped with a key lime cream cheese icing dipped in additional graham crackers.',
    snippet: 'Snippet Here...',
    prev_price: '',
    current_price: '',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: true,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
  // {
  //   title: 'A Taste Of Sunshine',
  //   description:
  //     'Lemon cake filled with a lemon curd, topped with a lemon buttercream and lemon zest.',
  //   snippet: 'Snippet Here...',
  //   prev_price: '',
  //   current_price: '',
  //   discount: 0.15,
  //   image: 'drip.jpg',
  //   link: 'order',
  //   type: 'sweet',
  //   flavors: '',
  //   isSpecialty: true,
  //   isBestSeller: false,
  //   isFeatured: false,
  //   isOnSale: false,
  // },
  {
    title: 'Peanut Butter Supreme',
    description:
      'Reese’s Cup baked in a chocolate cupcake, topped with peanut butter buttercream (with or without nuts).',
    snippet: 'Snippet Here...',
    prev_price: '',
    current_price: '',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: true,
    isBestSeller: true,
    isFeatured: false,
    isOnSale: false,
  },
  {
    title: "Sam's Banana Pudding",
    description:
      'Vanilla cake with a banana pudding filling, topped with a whipped topping and vanilla wafer.',
    snippet: 'Snippet Here...',
    prev_price: '',
    current_price: '',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: true,
    isBestSeller: true,
    isFeatured: false,
    isOnSale: false,
  },
  // {
  //   title: 'Strawberry Delight',
  //   description:
  //     'Vanilla cake with a strawberry filling, topped with layer of white chocolate and a strawberry white chocolate cream cheese frosting and white chocolate curls.',
  //   snippet: 'Snippet Here...',
  //   prev_price: '',
  //   current_price: '',
  //   discount: 0.15,
  //   image: 'drip.jpg',
  //   link: 'order',
  //   type: 'sweet',
  //   flavors: '',
  //   isSpecialty: true,
  //   isBestSeller: false,
  //   isFeatured: false,
  //   isOnSale: false,
  // },
  {
    title: 'Cookies & Cream',
    description:
      'Chocolate cupcake filled and topped with a cookie crumb buttercream icing; topped off with a classic Oreo cookie.',
    snippet: 'Snippet Here...',
    prev_price: '',
    current_price: '',
    discount: 0.15,
    image: 'drip.jpg',
    link: 'order',
    type: 'sweet',
    flavors: '',
    isSpecialty: true,
    isBestSeller: false,
    isFeatured: false,
    isOnSale: false,
  },
];
