export const cta = [
  {
    title: 'Serving middle Georgia and surrounding areas',
    byLine: '',
    byline_link: '/order',
    message:
      'Our desserts range from cakes, cupcakes, lemon squares, and so much more. We take orders for parties, weddings, baby showers, and all other special occasions. All of our desserts are baked fresh and upon request, to ensure the best quality of the product.  And to top it off, we’re focused on the essential values and commitment to you…our customer, which is our main ingredient for success.',
    image: 'logo2.png',
    alt: 'Logo Image',
    order_1: 'order-md-1',
    order_2: 'order-md-2',
  },
  {
    title: 'Ask About Our Special Packaging',
    byLine: '',
    byline_link: '/order',
    message:
      'During this COVID pandemic it\'s important that we all keep safe. With this in mind, we offer special packaging for cupcakes, cookies, and pretzel rods. Please let us know during your consultation if you would like for your items to be packaged individually and we can accommodate. Additional charges apply.',
    image: 'packaging.png',
    alt: 'Packaging Image',
    order_1: 'order-md-2',
    order_2: 'order-md-1',
  },
  // {
  //   title: 'Holiday Pound Cakes',
  //   byLine: 'Order by 11/3/2020',
  //   byline_link: '/',
  //   message:
  //     'Need to bring something special to Thanksgiving dinner?  Order your favorite pound cake and share with the family.',
  //   image: 'logo.png',
  //   alt: 'Logo Image',
  //   order_1: 'order-md-2',
  //   order_2: 'order-md-1',
  // }
];
